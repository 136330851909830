import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { searchTickers } from '../../api/searchTickers';
import { isDomestic, COUNTRY } from '../../lib/utils';
import { TICKCOLOR_UP, TICKCOLOR_DOWN, TICKCOLOR_NEUTRAL } from '../../lib/constants';
import { Autocomplete, Box, Container, Stack, TextField } from '@mui/material';
import SearchSummary from './SearchSummary';
import SearchHistoryChart from './SearchHistoryChart';
import SearchSimulation from './SearchSimulation';

const SearchAsset = () => {
  const navigate = useNavigate();

  const { token, preferences } = useSelector(state => state.auth);
  const tradingMode = preferences.tradingMode;

  const [tickers, setTickers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [assetDetails, setAssetDetails] = useState(null);
  const [country, setCountry] = useState(COUNTRY.KOREA);
  const [plotData, setPlotData] = useState([]);

  const handleChange = async e => {
    if (typeof e.target.value === 'string') {
      if (e.target.value.length === 0) setTickers([]);
      else {
        const tickers = await searchTickers(token, tradingMode, e.target.value);

        setTickers(tickers);
      }
    }
  };

  const getAssetHistory = async (ticker, country) => {
    const API_URL = `/api/${tradingMode.toLowerCase()}/assets/history/`;

    try {
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`,
          'cache-control': 'no-store',
          pragma: 'no-store',
          expires: '0'
        },
        params: {
          ticker,
          country
        }
      };

      const response = await axios.get(API_URL + ticker, config);

      return response.data;
    } catch (error) {
      console.log(error.response.data);
      toast.error('Error: Invalid KIS credentials. Please update your credentials and try again later.');
      return 0;
    }
  };

  const displayDetails = async ticker => {
    const country = ticker.country;
    const marketCode = ticker.marketCode || null;

    setCountry(country);
    setIsSearching(true);

    try {
      const { props, priceHistory: prices } = await getAssetHistory(ticker.ticker, country);
      const change = props.close - props.yclose;
      const changeRatio = ((props.close - props.yclose) / props.yclose) * 100;
      const tick = change > 0 ? 'UP' : change < 0 ? 'DOWN' : 'HOLD';
      const tickColor = change > 0 ? TICKCOLOR_UP : change < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL;

      // console.log(prices);
      const uniq = (data, key) => {
        return [...new Map(data.map(item => [key(item), item])).values()];
      };

      const priceHistory = JSON.parse(JSON.stringify(uniq(prices, x => x.date)));

      setAssetDetails({
        name: ticker.name,
        ticker: ticker.ticker,
        close: isDomestic(country)
          ? props.close.toLocaleString('en-us')
          : `$${props.close.toFixed(2).toLocaleString('en-us')}`,
        yclose: isDomestic(country)
          ? props.yclose.toLocaleString('en-us')
          : `$${props.yclose.toFixed(2).toLocaleString('en-us')}`,
        change: isDomestic(country)
          ? Math.abs(change).toLocaleString('en-us')
          : `$${Math.abs(change).toFixed(2).toLocaleString('en-us')}`,
        changeRatio: isDomestic(country)
          ? `${Math.abs(changeRatio).toFixed(2).toLocaleString('en-us')}%`
          : `${Math.abs(changeRatio).toFixed(2).toLocaleString('en-us')}%`,
        avg5: isDomestic(country)
          ? props.avg5?.toLocaleString('en-us')
          : `$${props.avg5?.toFixed(2).toLocaleString('en-us')}`,
        avg20: isDomestic(country)
          ? props.avg20?.toLocaleString('en-us')
          : `$${props.avg20?.toFixed(2).toLocaleString('en-us')}`,
        volume: props.volume.toLocaleString('en-us'),
        marketCap: isDomestic(country)
          ? `${Math.ceil(props.marketcap / 10).toLocaleString('en-us')}B`
          : `$${Math.ceil(props.marketcap).toLocaleString('en-us')}B`,
        signal: props.bsignal,
        marketCode,
        country,
        tick,
        tickColor
      });

      priceHistory.length > 0 && setPlotData(priceHistory);
    } catch (error) {
      toast.error('Error: Invalid KIS credentials. Please update your credentials and try again later.');
    }
  };

  useEffect(() => {
    if (!token) {
      return navigate('/login');
    }
  }, [token, navigate]);

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '720px', lg: '1000px' }}
        sx={{ width: '100%', margin: 'auto', mt: 8, typography: 'body1' }}
      >
        <Stack spacing={2} sx={{ width: '100%', margin: 'auto' }}>
          <Autocomplete
            id='stock_search_autocomplete'
            freeSolo
            clearOnEscape
            clearOnBlur
            filterOptions={x => x}
            getOptionLabel={option => option.name}
            options={tickers}
            isOptionEqualToValue={(option, value) => option.ticker === value.ticker}
            noOptionsText={'No matching asset found'}
            renderOption={(props, option) => {
              return (
                <Box component={'li'} {...props} key={option.ticker}>
                  {option.name} ({option.ticker})
                </Box>
              );
            }}
            renderInput={params => <TextField {...params} label='Search an asset...' />}
            onChange={(e, newValue) => {
              if (newValue !== null) {
                setAssetDetails(null);
                displayDetails(newValue);
              }
            }}
            onInputChange={debounce(handleChange, 300)}
          />
        </Stack>

        {/* asset details */}
        {assetDetails && <SearchSummary assetDetails={assetDetails} />}

        {/* asset price history chart */}
        {plotData.length > 0 && <SearchHistoryChart plotData={plotData} country={country} />}

        {/* profit/loss simulation */}
        {isSearching && assetDetails && <SearchSimulation asset={assetDetails} />}
      </Box>
    </Container>
  );
};

export default SearchAsset;
