import moment from 'moment-timezone';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Stack, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import AssistantIcon from '@mui/icons-material/Assistant';
import { simulateFund } from '../../api/simulateFund';
import SimulationSummary from './SimulationSummary';
import PerformanceChart from './PerformanceChart';
import DailyPerformance from './DailyPerformance';

const Simulation = () => {
  const navigate = useNavigate();

  const { token, preferences } = useSelector(state => state.auth);
  const tradingMode = preferences.tradingMode;

  const today = moment.tz(new Date(), 'Asia/Seoul').subtract(1, 'd').format('YYYY-MM-DD');
  let fromDate = moment.tz(new Date(), 'Asia/Seoul').subtract(1, 'M').format('YYYY-MM-DD');

  const [result, setResult] = useState(null);
  const [fund, setFund] = useState('PRIMARY');

  const isIndexFund = fund === 'INDEX' || fund === 'INDEX2';

  const [country, setCountry] = useState('KOREA');
  const [period, setPeriod] = useState('1M');
  const [ai, setAI] = useState('AI0');
  const [tradingPattern, setTradingPattern] = useState('DEFAULT');
  const [weightedSignal, setWeightedSignal] = useState(false);
  const [profitTakingRatio, setProfitTakingRatio] = useState('0');
  const [lossCutRatio, setLossCutRatio] = useState('0');
  const [maxBudget, setMaxBudget] = useState(isIndexFund ? '1.0' : '0.3');
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(today);
  const [isLoading, setIsLoading] = useState(false);
  const [plotData, setPlotData] = useState([]);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const runSimulation = async () => {
    setResult(null);

    setIsLoading(true);

    try {
      const result = await simulateFund(
        token,
        tradingMode,
        fund,
        startDate,
        endDate,
        ai,
        weightedSignal,
        tradingPattern,
        profitTakingRatio,
        lossCutRatio,
        maxBudget,
        country
      );

      setResult({
        fund,
        startDate,
        endDate,
        ai:
          ai === 'AI1'
            ? 'AI 1'
            : ai === 'AI2'
            ? 'AI 2'
            : ai === 'AI3'
            ? 'AI 3'
            : ai === 'AI4'
            ? 'AI 4'
            : ai === 'AI5'
            ? 'AI 5'
            : 'AI 0',
        accountBalance: result.accountBalance,
        assetBalance: result.assetBalance,
        cashBalance: result.cashBalance,
        profit: result.profit,
        profitRatio: result.profitRatio,
        performanceSummary: result.performanceSummary
      });

      result.performanceSummary.length > 0 && setPlotData(result.performanceSummary);
    } catch (error) {
      if (
        error.response?.data?.message === 'Expired token' ||
        error.response?.data?.message === 'Invalid token' ||
        error.response?.data?.message === 'Not authorized'
      )
        return navigate('/login');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (!token) {
      return navigate('/login');
    }

    const month = period === '1M' || period === 'CUSTOM' ? 1 : period === '3M' ? 3 : 6;
    const fromDate = moment.tz(new Date(), 'Asia/Seoul').subtract(month, 'M').format('YYYY-MM-DD');

    setStartDate(fromDate);
  }, [token, navigate, period]);

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Typography
        component='h1'
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={2}
        m={isMobile ? 4 : 6}
        fontSize={{ xs: '1.2rem', sm: '1.5rem' }}
      >
        <AssistantIcon fontSize={isMobile ? 'medium' : 'large'} /> Fund Simulation
      </Typography>

      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '720px', lg: '1000px' }}
        sx={{ width: '100%', margin: 'auto', mt: 10, typography: 'body1' }}
      >
        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={2}
        >
          <Typography variant='body2' textAlign={'end'}>
            Trade Market
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={country}
            exclusive
            onChange={(e, country) => {
              if (country !== null) setCountry(country);
            }}
            sx={{ width: { xs: '240px', sm: '340px' } }}
          >
            <ToggleButton value='KOREA'>KOREA</ToggleButton>
            <ToggleButton value='USA'>USA</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' textAlign={'end'}>
            Fund
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={fund}
            exclusive
            onChange={(e, fund) => {
              if (fund !== null) setFund(fund);
            }}
            sx={{ width: { xs: '240px', sm: '340px' } }}
          >
            <ToggleButton value='PRIMARY'>PRIMARY</ToggleButton>
            <ToggleButton value='SECONDARY'>SECONDARY</ToggleButton>
            <ToggleButton value='INDEX'>INDEX</ToggleButton>
            <ToggleButton value='INDEX2'>INDEX2</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' textAlign={'end'}>
            Period
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={period}
            exclusive
            onChange={(e, period) => {
              if (period !== null) setPeriod(period);
            }}
            sx={{ width: { xs: '240px', sm: '340px' } }}
          >
            <ToggleButton value='1M'>1 mon</ToggleButton>
            <ToggleButton value='3M'>3 mon</ToggleButton>
            <ToggleButton value='6M'>6 mon</ToggleButton>
            <ToggleButton value='CUSTOM'>Custom</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        {period === 'CUSTOM' && (
          <Stack
            display={'grid'}
            gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
            alignItems={'center'}
            gap={0}
            px={{ xs: 0, sm: 18 }}
            mt={3}
          >
            <Typography variant='body2' textAlign={'end'}>
              {''}
            </Typography>
            <Box display={'flex'} alignItems={'center'}>
              <DatePicker
                label='From'
                sx={{ width: '150px' }}
                minDate={moment('2024-01-01')}
                maxDate={moment(today)}
                value={moment(startDate)}
                onChange={newValue => setStartDate(moment(newValue).format('YYYY-MM-DD'))}
              />
              <Typography px={2}>-</Typography>
              <DatePicker
                label='To'
                sx={{ width: '150px' }}
                minDate={moment(startDate)}
                maxDate={moment(today)}
                value={moment(endDate)}
                onChange={newValue => setEndDate(moment(newValue).format('YYYY-MM-DD'))}
              />
            </Box>
          </Stack>
        )}

        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' textAlign={'end'}>
            Algorithm
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={ai}
            exclusive
            onChange={(e, ai) => {
              if (ai !== null) setAI(ai);
            }}
            sx={{ width: { xs: '240px', sm: '340px' } }}
          >
            <ToggleButton value='AI0'>AI 0</ToggleButton>
            <ToggleButton value='AI1'>AI 1</ToggleButton>
            <ToggleButton value='AI2'>AI 2</ToggleButton>
            <ToggleButton value='AI3'>AI 3</ToggleButton>
            <ToggleButton value='AI4'>AI 4</ToggleButton>
            <ToggleButton value='AI5'>AI 5</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

        {(ai === 'AI0' || ai === 'AI1') && !isIndexFund && (
          <Stack
            display={'grid'}
            gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
            alignItems={'center'}
            gap={3}
            px={{ xs: 0, sm: 3 }}
            mt={4}
          >
            <Typography variant='body2' textAlign={'end'}>
              Weighted Signal
            </Typography>
            <ToggleButtonGroup
              size='small'
              color='primary'
              value={weightedSignal}
              exclusive
              onChange={(e, signal) => setWeightedSignal(signal)}
              sx={{ width: { xs: '240px', sm: '340px' } }}
            >
              <ToggleButton value={false}>Off</ToggleButton>
              <ToggleButton value={true}>On</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        )}

        {/* {!isIndexFund && ( */}
        <Stack
          display={'grid'}
          gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
          alignItems={'center'}
          gap={3}
          px={{ xs: 0, sm: 3 }}
          mt={4}
        >
          <Typography variant='body2' textAlign={'end'}>
            Trade Pattern
          </Typography>
          <ToggleButtonGroup
            size='small'
            color='primary'
            value={tradingPattern}
            exclusive
            onChange={(e, pattern) => {
              if (pattern !== null) setTradingPattern(pattern);
            }}
            sx={{ width: { xs: '240px', sm: '340px' } }}
          >
            <ToggleButton value='DEFAULT'>Default</ToggleButton>
            <ToggleButton value='PROFITLOSS'>Profit Loss</ToggleButton>
            {/* <ToggleButton value='LOSSCUT'>Loss Cut</ToggleButton> */}
          </ToggleButtonGroup>
        </Stack>
        {/* )} */}

        {/* {!isIndexFund && tradingPattern === 'LOSSCUT' && ( */}
        {tradingPattern === 'PROFITLOSS' && (
          <>
            <Stack
              display={'grid'}
              gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
              alignItems={'center'}
              gap={3}
              px={{ xs: 0, sm: 3 }}
              mt={3}
            >
              <Typography variant='body2' textAlign={'end'}>
                Profit Taking Ratio
              </Typography>
              <Box display={'flex'} alignItems={'center'}>
                <ToggleButtonGroup
                  size='small'
                  color='primary'
                  value={profitTakingRatio}
                  exclusive
                  onChange={(e, value) => {
                    if (value !== null) setProfitTakingRatio(value);
                  }}
                  sx={{ width: { xs: '240px', sm: '340px' } }}
                >
                  <ToggleButton value='0'>0%</ToggleButton>
                  <ToggleButton value='2'>2%</ToggleButton>
                  <ToggleButton value='5'>5%</ToggleButton>
                  <ToggleButton value='10'>10%</ToggleButton>
                  <ToggleButton value='15'>15%</ToggleButton>
                  <ToggleButton value='20'>20%</ToggleButton>
                  <ToggleButton value='25'>25%</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Stack>

            <Stack
              display={'grid'}
              gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
              alignItems={'center'}
              gap={3}
              px={{ xs: 0, sm: 3 }}
              mt={3}
            >
              <Typography variant='body2' textAlign={'end'}>
                Loss Cut Ratio
              </Typography>
              <Box display={'flex'} alignItems={'center'}>
                <ToggleButtonGroup
                  size='small'
                  color='primary'
                  value={lossCutRatio}
                  exclusive
                  onChange={(e, value) => {
                    if (value !== null) setLossCutRatio(value);
                  }}
                  sx={{ width: { xs: '240px', sm: '340px' } }}
                >
                  <ToggleButton value='0'>0%</ToggleButton>
                  <ToggleButton value='1'>1%</ToggleButton>
                  <ToggleButton value='2'>2%</ToggleButton>
                  <ToggleButton value='3'>3%</ToggleButton>
                  <ToggleButton value='4'>4%</ToggleButton>
                  <ToggleButton value='5'>5%</ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Stack>
          </>
        )}

        {!isIndexFund && (
          <Stack
            display={'grid'}
            gridTemplateColumns={{ xs: '25% 75%', sm: '40% 60%' }}
            alignItems={'center'}
            gap={3}
            px={{ xs: 0, sm: 3 }}
            mt={4}
          >
            <Typography variant='body2' textAlign={'end'}>
              Max Budget
            </Typography>
            <ToggleButtonGroup
              size='small'
              color='primary'
              value={maxBudget}
              exclusive
              onChange={(e, maxBudget) => {
                if (maxBudget !== null) setMaxBudget(maxBudget);
              }}
              sx={{ width: { xs: '240px', sm: '340px' } }}
            >
              <ToggleButton value='0.3'>30%</ToggleButton>
              <ToggleButton value='0.5'>50%</ToggleButton>
              <ToggleButton value='1.0'>100%</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        )}

        <Stack display={'flex'} justifyContent={'center'} alignItems={'center'} gap={4} mt={4}>
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            startIcon={<AssistantIcon />}
            variant='contained'
            sx={{ width: '150px' }}
            onClick={() => runSimulation()}
          >
            Simulate
          </LoadingButton>
        </Stack>

        {/* simulation result */}
        <Box
          display={'flex'}
          justifyContent={'center'}
          gap={4}
          maxWidth={{ xs: '360px', sm: '600px', md: '720px', lg: '1000px' }}
          sx={{ width: '100%', margin: 'auto', mt: 4, typography: 'body1' }}
        >
          {result && (
            <Stack spacing={4} sx={{ width: '100%', margin: { xs: '2rem auto', md: '4rem auto' } }}>
              {/* simulation result summary */}
              <SimulationSummary result={result} country={country} />

              {/* simulation result chart */}
              <PerformanceChart plotData={plotData} country={country} />

              {/* simulation result details with daily data */}
              <DailyPerformance result={result} country={country} />
            </Stack>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Simulation;
