import { Stack, Typography } from '@mui/material';
import {
  ChartsAxisHighlight,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LineHighlightPlot,
  LinePlot,
  ResponsiveChartContainer
} from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { isDomestic } from '../../lib/utils';

const SearchHistoryChart = ({ plotData, country }) => {
  const series = [
    {
      type: 'line',
      yAxisId: 'price',
      label: 'Price',
      color: '#00AEEF',
      data: plotData.map(day => day.close),
      highlightScope: { highlight: 'item' }
    },
    // {
    //   type: 'bar',
    //   yAxisId: 'open',
    //   label: 'Open',
    //   data: plotData.map(day => day.open)
    // },
    // {
    //   type: 'bar',
    //   yAxisId: 'close',
    //   label: 'Close',
    //   data: plotData.map(day => day.close)
    // },
    {
      type: 'bar',
      yAxisId: 'signal',
      label: 'Signal',
      data: plotData.map(day => (day.bsignal === 0 ? 'Sell' : day.bsignal === 1 ? 'Buy' : 'Hold'))
    }
    // {
    //   type: 'bar',
    //   yAxisId: 'avg5',
    //   label: '5-day Average',
    //   data: plotData.map(day => day.avg5)
    // },
    // {
    //   type: 'bar',
    //   yAxisId: 'avg20',
    //   label: '20-day Average',
    //   data: plotData.map(day => day.avg20)
    // }
  ];

  return (
    <Stack spacing={2} sx={{ width: '100%', margin: { xs: '2rem auto', md: '3rem auto' } }}>
      <Typography color={'primary'}>Price History</Typography>
      <ResponsiveChartContainer
        series={series}
        height={450}
        margin={{ top: 16, left: 80, right: 10 }}
        xAxis={[
          {
            id: 'date',
            data: plotData.map(day => new Date(day.date)),
            scaleType: 'band',
            valueFormatter: value => value.toLocaleDateString()
          }
        ]}
        yAxis={[
          {
            id: 'price',
            scaleType: 'linear',
            valueFormatter: value => {
              if (isDomestic(country)) {
                return value;
              } else {
                return '$' + value;
              }
            }
          }
        ]}
      >
        <ChartsAxisHighlight x='line' />
        <LinePlot />
        <LineHighlightPlot />
        <ChartsXAxis
          label='Date'
          position='bottom'
          axisId='date'
          tickInterval={(value, index) => {
            return index % 3 === 0;
          }}
          tickLabelStyle={{ fontSize: 12 }}
        />
        <ChartsYAxis
          label={isDomestic(country) ? 'Price (KRW)' : 'Price (USD)'}
          position='left'
          axisId='price'
          tickLabelStyle={{ fontSize: 12 }}
          sx={{
            [`& .${axisClasses.label}`]: {
              transform: 'translateX(-30px)'
            }
          }}
        />
        <ChartsTooltip
          slotProps={{
            popper: {
              sx: {
                '& .MuiChartsTooltip-paper': {
                  backgroundColor: 'black'
                }
              }
            }
          }}
        />
      </ResponsiveChartContainer>
    </Stack>
  );
};

export default SearchHistoryChart;
