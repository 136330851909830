import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { darkTheme } from './lib/theme';
import ScrollToHashElement from './components/utils/ScrollToHashElement';
import ScrollToTop from './components/utils/ScrollToTop';
import Header from './components/navbar/Header';
import Landing from './pages/Landing';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Profile from './components/home/Profile';
import Preferences from './components/settings/Preferences';
import Credentials from './components/settings/Credentials';
import Portfolio from './pages/Portfolio';
import SearchAsset from './components/search/SearchAsset';
import MyFund from './components/myfund/MyFund';
import Simulation from './components/simulation/Simulation';
import TradeLog from './components/home/TradeLog';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import TokenVerify from './components/TokenVerify';
import Privacy from './components/legal/Privacy';
import Tos from './components/legal/Tos';
import NotFound from './pages/NotFound';

function App() {
  const { token } = useSelector(state => state.auth);

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Router>
        <ScrollToTop />
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <Header>
            <Routes>
              <Route path='/' element={<Landing />} />
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/forgotpassword' element={<ForgotPassword />} />
              <Route path='/resetpassword/:resetToken' element={<ResetPassword />} />
              <Route path='/legal/privacy' element={<Privacy />} />
              <Route path='/legal/tos' element={<Tos />} />

              <Route path='' element={<PrivateRoute />}>
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/search' element={<SearchAsset />} />
                <Route path='/myfund' element={<MyFund />} />
                <Route path='/simulation' element={<Simulation />} />
                <Route path='/portfolio' element={<Portfolio />} />
                <Route path='/log' element={<TradeLog />} />
                <Route path='/profile' element={<Profile />} />
                <Route path='/credentials' element={<Credentials />} />
                <Route path='/settings' element={<Preferences />} />
              </Route>

              <Route path='/*' element={<NotFound />} />
            </Routes>
          </Header>
          {!token && <Footer />}
          <TokenVerify />
        </ThemeProvider>
        <ScrollToHashElement />
      </Router>

      <ToastContainer autoClose={1500} pauseOnFocusLoss={false} theme={'colored'} />
    </div>
  );
}

export default App;
