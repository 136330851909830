import { Box, Divider, Grid, Link, styled, Tooltip, Typography } from '@mui/material';
import { isDomestic } from '../../lib/utils';
import { TICKCOLOR_UP, TICKCOLOR_DOWN, TICKCOLOR_NEUTRAL, tooltipStyle } from '../../lib/constants';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const DailyPerformance = ({ result, country }) => {
  const Item = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.body2
    // padding: theme.spacing(1),
  }));

  return (
    <>
      <Box display={'flex'} gap={2} pt={4} flexDirection={{ xs: 'column', md: 'row' }}>
        <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
          <Typography variant='h6'>Daily Performance</Typography>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={1}
        mt={0}
        sx={{ overflowWrap: 'anywhere' }}
      >
        {result.performanceSummary.length === 0 ? (
          <Typography>No trade transactions during the selected period.</Typography>
        ) : (
          result.performanceSummary.map(dailyResult => (
            <Grid container spacing={1} key={dailyResult.date}>
              <Grid item xs={3}>
                <Item color={'primary'}>Date: {dailyResult.date}</Item>
              </Grid>
              <Grid item xs={3}>
                <Item>Account Balance</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  {isDomestic(country)
                    ? Math.abs(dailyResult.accountBalance).toLocaleString('en-us')
                    : `$${Math.abs(dailyResult.accountBalance).toLocaleString('en-us', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}`}
                </Item>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Item>Cash Balance</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  {isDomestic(country)
                    ? Math.abs(dailyResult.cashBalance).toLocaleString('en-us')
                    : `$${Math.abs(dailyResult.cashBalance).toLocaleString('en-us', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}`}
                </Item>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Item>Asset Balance</Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  {isDomestic(country)
                    ? Math.abs(dailyResult.assetBalance).toLocaleString('en-us')
                    : `$${Math.abs(dailyResult.assetBalance).toLocaleString('en-us', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}`}
                </Item>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Item>Profit</Item>
              </Grid>
              <Grid item xs={6}>
                <Item
                  color={
                    dailyResult.profit && dailyResult.profit > 0
                      ? TICKCOLOR_UP
                      : dailyResult.profit && dailyResult.profit < 0
                      ? TICKCOLOR_DOWN
                      : TICKCOLOR_NEUTRAL
                  }
                >
                  {dailyResult.profit > 0 ? (
                    <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
                  ) : dailyResult.profit < 0 ? (
                    <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
                  ) : (
                    ''
                  )}
                  {isDomestic(country)
                    ? Math.abs(dailyResult.profit).toLocaleString('en-us')
                    : `$${Math.abs(dailyResult.profit).toFixed(2).toLocaleString('en-us')}`}
                </Item>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={3}>
                <Item>Profit %</Item>
              </Grid>
              <Grid
                item
                xs={6}
                color={
                  dailyResult.profitRatio > 0
                    ? TICKCOLOR_UP
                    : dailyResult.profitRatio < 0
                    ? TICKCOLOR_DOWN
                    : TICKCOLOR_NEUTRAL
                }
              >
                <Item>
                  {dailyResult.profitRatio > 0 ? (
                    <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
                  ) : dailyResult.profitRatio < 0 ? (
                    <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
                  ) : (
                    ''
                  )}
                  {Math.abs(dailyResult.profitRatio).toFixed(2).toLocaleString('en-us') + '%'}
                </Item>
              </Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={9}>
                <Item color={'primary'}>Portfolio</Item>
              </Grid>
              {dailyResult.portfolio.length === 0 ? (
                <>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={9}>
                    <Item>No assets</Item>
                  </Grid>
                </>
              ) : (
                dailyResult.portfolio.map(asset => (
                  <Grid container spacing={1} key={asset.ticker} pl={'8px'} pt={'8px'}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                      <Divider></Divider>
                    </Grid>

                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                      <Tooltip
                        disableFocusListener
                        arrow
                        title={`${asset.name} (${asset.ticker})`}
                        PopperProps={{ sx: tooltipStyle }}
                      >
                        <Link
                          color='primary.light'
                          underline='none'
                          href={
                            isDomestic(country)
                              ? `https://finance.naver.com/item/main.nhn?code=${asset.ticker}`
                              : `https://finance.yahoo.com/quote/${asset.ticker}?p=${asset.ticker}`
                          }
                          target='_blank'
                          rel='noreferrer'
                        >
                          <Item
                            color={'#90caf9'}
                            display={'inline-block'}
                            overflow={'hidden'}
                            whiteSpace={'nowrap'}
                            textOverflow={'ellipsis'}
                            maxWidth={'220px'}
                          >
                            {asset.name}
                          </Item>
                        </Link>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                      <Item>Bought Date</Item>
                    </Grid>
                    <Grid item xs={3}>
                      <Item>{asset.buyAt}</Item>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                      <Item>Bought Price</Item>
                    </Grid>
                    <Grid item xs={3}>
                      <Item>
                        {isDomestic(country)
                          ? Math.abs(asset.buyPrice).toLocaleString('en-us')
                          : `$${Math.abs(asset.buyPrice).toFixed(2).toLocaleString('en-us')}`}
                      </Item>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                      <Item>Quantity</Item>
                    </Grid>
                    <Grid item xs={3}>
                      <Item>{asset.quantity?.toLocaleString('en-us')}</Item>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                      <Item>Profit</Item>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      color={asset.profit > 0 ? TICKCOLOR_UP : asset.profit < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL}
                    >
                      <Item>
                        {asset.profit > 0 ? (
                          <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
                        ) : asset.profit < 0 ? (
                          <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
                        ) : (
                          ''
                        )}
                        {isDomestic(country)
                          ? Math.abs(asset.profit).toLocaleString('en-us')
                          : `$${Math.abs(asset.profit).toFixed(2).toLocaleString('en-us')}`}
                      </Item>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                      <Item>Profit %</Item>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      color={
                        asset.profitRatio > 0
                          ? TICKCOLOR_UP
                          : asset.profitRatio < 0
                          ? TICKCOLOR_DOWN
                          : TICKCOLOR_NEUTRAL
                      }
                    >
                      <Item>
                        {asset.profitRatio > 0 ? (
                          <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
                        ) : asset.profitRatio < 0 ? (
                          <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
                        ) : (
                          ''
                        )}
                        {Math.abs(asset.profitRatio).toFixed(2).toLocaleString('en-us') + '%'}
                      </Item>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                      <Item>Open</Item>
                    </Grid>
                    <Grid item xs={3}>
                      <Item>
                        {isDomestic(country)
                          ? Math.abs(asset.open).toLocaleString('en-us')
                          : `$${Math.abs(asset.open).toFixed(2).toLocaleString('en-us')}`}
                      </Item>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                      <Item>Close</Item>
                    </Grid>
                    <Grid item xs={3}>
                      <Item>
                        {isDomestic(country)
                          ? Math.abs(asset.close).toLocaleString('en-us')
                          : `$${Math.abs(asset.close).toFixed(2).toLocaleString('en-us')}`}
                      </Item>
                    </Grid>
                  </Grid>
                ))
              )}
              <Grid item xs={12}>
                <Divider sx={{ margin: '8px 0' }}></Divider>
              </Grid>
            </Grid>
          ))
        )}
      </Box>
    </>
  );
};

export default DailyPerformance;
