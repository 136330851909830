import { Box, Chip, Link, Stack, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddFund from '../myfund/AddFund';

const SearchSummary = ({ assetDetails }) => {
  const tableStyle = {
    fontSize: '14px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    lineHeight: '52px',
    paddingLeft: '1rem'
  };

  return (
    <Stack spacing={2} sx={{ width: '100%', margin: { xs: '2rem auto', md: '3rem auto' } }}>
      <Box display={'flex'} gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
        <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
          <Link
            color='primary.light'
            underline='none'
            href={
              assetDetails.country === 'KOREA'
                ? `https://finance.naver.com/item/main.naver?code=${assetDetails.ticker}`
                : `https://finance.yahoo.com/quote/${assetDetails.ticker}?p=${assetDetails.ticker}`
            }
            target='_blank'
            rel='noreferrer'
          >
            <Typography color={'#90caf9'}>{assetDetails.name}</Typography>
          </Link>
          <Typography>({assetDetails.ticker})</Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={2} justifyContent={'space-between'} width={'100%'}>
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography color={assetDetails.tickColor}>{assetDetails.close}</Typography>
            <Typography color={assetDetails.tickColor} display={'flex'} alignItems={'center'}>
              {assetDetails.tick === 'UP' ? (
                <ArrowDropUpIcon />
              ) : assetDetails.tick === 'DOWN' ? (
                <ArrowDropDownIcon />
              ) : (
                ''
              )}
              {assetDetails.change.toLocaleString('en-us')} ({assetDetails.changeRatio})
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={4}>
            {assetDetails.signal === 0 ? (
              <Chip label='SELL' color='success' sx={{ width: '62px' }} />
            ) : assetDetails.signal === 1 ? (
              <Chip label='BUY' color='error' sx={{ width: '62px' }} />
            ) : assetDetails.signal === 2 ? (
              <Chip label='HOLD' color='secondary' sx={{ width: '62px' }} />
            ) : assetDetails.signal === 99 ? (
              <Chip label='DELISTED' />
            ) : (
              ''
            )}
            {assetDetails.signal !== 99 && <AddFund asset={assetDetails} />}
          </Box>
        </Box>
      </Box>

      <Box
        display={'grid'}
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
        border={'1px solid rgba(255, 255, 255, 0.2)'}
        borderRadius={'6px'}
      >
        <Typography style={tableStyle} backgroundColor='#001021'>
          Close
        </Typography>
        <Typography style={tableStyle}>{assetDetails.close}</Typography>
        <Typography style={tableStyle} backgroundColor='#001021'>
          Prev Close
        </Typography>
        <Typography style={tableStyle}>{assetDetails.yclose}</Typography>
        {/* <Typography style={tableStyle} backgroundColor='#001021'>
                Foreign Ratio
              </Typography>
              <Typography style={tableStyle} color={assetDetails.openColor}>
                {assetDetails.foreignRatio}
              </Typography> */}
        <Typography style={tableStyle} backgroundColor='#001021'>
          5-day Avg
        </Typography>
        <Typography style={tableStyle} color={assetDetails.highColor}>
          {assetDetails.avg5}
        </Typography>
        <Typography style={tableStyle} backgroundColor='#001021'>
          20-day Avg
        </Typography>
        <Typography style={tableStyle} color={assetDetails.lowColor}>
          {assetDetails.avg20}
        </Typography>
        {/* <Typography style={tableStyle} backgroundColor='#001021'>
                52W High
              </Typography>
              <Typography style={tableStyle}>{assetDetails.high52w}</Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                52W Low
              </Typography>
              <Typography style={tableStyle}>{assetDetails.low52w}</Typography> */}
        <Typography style={tableStyle} backgroundColor='#001021'>
          Volume
        </Typography>
        <Typography style={tableStyle}>{assetDetails.volume}</Typography>
        <Typography style={tableStyle} backgroundColor='#001021'>
          Market Cap
        </Typography>
        <Typography style={tableStyle}>{assetDetails.marketCap}</Typography>
      </Box>
    </Stack>
  );
};
export default SearchSummary;
