import moment from 'moment-timezone';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Button, Skeleton, Stack, styled, Tooltip, Typography } from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { isDomestic } from '../../lib/utils';
import { TICKCOLOR_UP, TICKCOLOR_DOWN, TICKCOLOR_NEUTRAL, tooltipStyle } from '../../lib/constants';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';
import { simulateAsset } from '../../api/simulateAsset';

const SearchSimulation = ({ asset }) => {
  const navigate = useNavigate();

  const { token, preferences } = useSelector(state => state.auth);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSimulating, setIsSimulating] = useState(false);
  const [result, setResult] = useState(null);

  const tradingMode = preferences.tradingMode;
  const country = asset.country;

  const endDate = moment.tz(new Date(), 'Asia/Seoul').subtract(1, 'd').format('YYYY-MM-DD');
  const startDate1M = moment.tz(new Date(), 'Asia/Seoul').subtract(1, 'M').format('YYYY-MM-DD');
  const startDate3M = moment.tz(new Date(), 'Asia/Seoul').subtract(3, 'M').format('YYYY-MM-DD');
  const startDate6M = moment.tz(new Date(), 'Asia/Seoul').subtract(6, 'M').format('YYYY-MM-DD');

  const Item = styled(Typography)(({ theme }) => ({
    display: 'flex',
    fontSize: '14px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    paddingLeft: '1rem',
    ...theme.typography.body2
  }));

  const tableStyle = {
    alignItems: 'center',
    lineHeight: '52px'
  };

  const tableStylePeriod = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    lineHeight: '24px'
  };

  const runSimulation = useCallback(async () => {
    setResult(null);
    setIsSimulating(true);

    try {
      const [result1M, result3M, result6M] = await Promise.all([
        simulateAsset(token, tradingMode, asset, startDate1M, endDate),
        simulateAsset(token, tradingMode, asset, startDate3M, endDate),
        simulateAsset(token, tradingMode, asset, startDate6M, endDate)
      ]);

      setResult({
        profit1M: result1M.profit,
        profitRatio1M: result1M.profitRatio,
        profit3M: result3M.profit,
        profitRatio3M: result3M.profitRatio,
        profit6M: result6M.profit,
        profitRatio6M: result6M.profitRatio
      });
    } catch (error) {
      console.log(error.response);
      if (
        error.response?.data?.message === 'Expired token' ||
        error.response?.data?.message === 'Invalid token' ||
        error.response?.data?.message === 'Not authorized'
      )
        return navigate('/login');
    }

    setIsSimulating(false);
  }, [asset, endDate, navigate, startDate1M, startDate3M, startDate6M, tradingMode, token]);

  const NoMaxWidthTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    {
      [`& .${tooltipClasses.tooltip}`]: {
        padding: '8px',
        maxWidth: '400px'
      }
    }
  );

  return (
    <Stack spacing={2} sx={{ width: '100%', margin: { xs: '2rem auto', md: '3rem auto' } }}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Typography color={'primary'}>Profit/Loss Simulation</Typography>
          <NoMaxWidthTooltip
            disableFocusListener
            arrow
            title={
              <>
                <Typography variant='body2'>
                  Display profit/loss simulation result if you purchased the asset for the following periods.
                </Typography>
                <Typography variant='body2' px={2} py={1}>
                  - Period: 1-mon, 3-mon, and 6-mon
                </Typography>
                <Typography variant='body2' px={2}>
                  - Starting balance: KRW 10,000,000 or USD 10,000
                </Typography>
              </>
            }
            PopperProps={{ sx: tooltipStyle }}
            placement='top'
            value='off'
          >
            <InfoIcon fontSize='small' color='primary' />
          </NoMaxWidthTooltip>
        </Box>
        <Box display={result ? 'none' : 'flex'} alignItems={'center'} justifyContent={'flex-left'}>
          <Typography color={'text.secondary'} pr={2}>
            Click to view simulation result
          </Typography>
          <Button variant='outlined' sx={{ padding: '0', minWidth: 0, width: '24px', height: '24px' }}>
            <KeyboardArrowDownIcon
              fontSize='small'
              onClick={() => {
                setIsExpanded(true);
                runSimulation();
              }}
            />
          </Button>
        </Box>
      </Box>

      {isSimulating ? (
        <Box
          display={'grid'}
          gridTemplateColumns={'repeat(3, 1fr)'}
          border={'1px solid rgba(255, 255, 255, 0.2)'}
          borderRadius={'6px'}
        >
          <Item style={tableStyle} backgroundColor='#001021'>
            Period
          </Item>
          <Item style={tableStyle} backgroundColor='#001021'>
            Profit/Loss
          </Item>
          <Item style={tableStyle} backgroundColor='#001021'>
            Profit/Loss Ratio
          </Item>
          <Item style={tableStyle}>
            <Skeleton animation='wave' variant='text' width={'95%'} />
          </Item>
          <Item style={tableStyle}>
            <Skeleton animation='wave' variant='text' width={'95%'} />
          </Item>
          <Item style={tableStyle}>
            <Skeleton animation='wave' variant='text' width={'95%'} />
          </Item>
          <Item style={tableStyle}>
            <Skeleton animation='wave' variant='text' width={'95%'} />
          </Item>
          <Item style={tableStyle}>
            <Skeleton animation='wave' variant='text' width={'95%'} />
          </Item>
          <Item style={tableStyle}>
            <Skeleton animation='wave' variant='text' width={'95%'} />
          </Item>
          <Item style={tableStyle}>
            <Skeleton animation='wave' variant='text' width={'95%'} />
          </Item>
          <Item style={tableStyle}>
            <Skeleton animation='wave' variant='text' width={'95%'} />
          </Item>
          <Item style={tableStyle}>
            <Skeleton animation='wave' variant='text' width={'95%'} />
          </Item>
        </Box>
      ) : (
        isExpanded && (
          <Box
            display={'grid'}
            gridTemplateColumns={'repeat(3, 1fr)'}
            border={'1px solid rgba(255, 255, 255, 0.2)'}
            borderRadius={'6px'}
          >
            <Item style={tableStyle} backgroundColor='#001021'>
              Period
            </Item>
            <Item style={tableStyle} backgroundColor='#001021'>
              Profit/Loss
            </Item>
            <Item style={tableStyle} backgroundColor='#001021'>
              Profit/Loss Ratio
            </Item>
            <Item style={tableStylePeriod}>
              1 Month
              <span style={{ color: '#999999' }}>
                ({startDate1M} ~ {endDate})
              </span>
            </Item>
            <Item
              style={tableStyle}
              color={
                result?.profit1M && result?.profit1M > 0
                  ? TICKCOLOR_UP
                  : result?.profit1M && result?.profit1M < 0
                  ? TICKCOLOR_DOWN
                  : TICKCOLOR_NEUTRAL
              }
            >
              {result?.profit1M > 0 ? (
                <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
              ) : result?.profit1M < 0 ? (
                <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
              ) : (
                ''
              )}
              {isDomestic(country)
                ? Math.abs(result?.profit1M).toLocaleString('en-us')
                : `$${Math.abs(result?.profit1M).toFixed(2).toLocaleString('en-us')}`}
            </Item>
            <Item
              style={tableStyle}
              color={
                result?.profitRatio1M && result?.profitRatio1M > 0
                  ? TICKCOLOR_UP
                  : result?.profitRatio1M && result?.profitRatio1M < 0
                  ? TICKCOLOR_DOWN
                  : TICKCOLOR_NEUTRAL
              }
            >
              {result?.profitRatio1M > 0 ? (
                <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
              ) : result?.profitRatio1M < 0 ? (
                <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
              ) : (
                ''
              )}
              {`${Math.abs(result?.profitRatio1M).toFixed(2).toLocaleString('en-us')}%`}
            </Item>
            <Item style={tableStylePeriod}>
              3 Months
              <span style={{ color: '#999999' }}>
                ({startDate3M} ~ {endDate})
              </span>
            </Item>
            <Item
              style={tableStyle}
              color={
                result?.profit3M && result?.profit3M > 0
                  ? TICKCOLOR_UP
                  : result?.profit3M && result?.profit3M < 0
                  ? TICKCOLOR_DOWN
                  : TICKCOLOR_NEUTRAL
              }
            >
              {result?.profit3M > 0 ? (
                <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
              ) : result?.profit3M < 0 ? (
                <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
              ) : (
                ''
              )}
              {isDomestic(country)
                ? Math.abs(result?.profit3M).toLocaleString('en-us')
                : `$${Math.abs(result?.profit3M).toFixed(2).toLocaleString('en-us')}`}
            </Item>
            <Item
              style={tableStyle}
              color={
                result?.profitRatio3M && result?.profitRatio3M > 0
                  ? TICKCOLOR_UP
                  : result?.profitRatio3M && result?.profitRatio3M < 0
                  ? TICKCOLOR_DOWN
                  : TICKCOLOR_NEUTRAL
              }
            >
              {result?.profitRatio3M > 0 ? (
                <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
              ) : result?.profitRatio3M < 0 ? (
                <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
              ) : (
                ''
              )}
              {`${Math.abs(result?.profitRatio3M).toFixed(2).toLocaleString('en-us')}%`}
            </Item>
            <Item style={tableStylePeriod}>
              6 Months
              <span style={{ color: '#999999' }}>
                ({startDate6M} ~ {endDate})
              </span>
            </Item>
            <Item
              style={tableStyle}
              color={
                result?.profit6M && result?.profit6M > 0
                  ? TICKCOLOR_UP
                  : result?.profit6M && result?.profit6M < 0
                  ? TICKCOLOR_DOWN
                  : TICKCOLOR_NEUTRAL
              }
            >
              {result?.profit6M > 0 ? (
                <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
              ) : result?.profit6M < 0 ? (
                <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
              ) : (
                ''
              )}
              {isDomestic(country)
                ? Math.abs(result?.profit6M).toLocaleString('en-us')
                : `$${Math.abs(result?.profit6M).toFixed(2).toLocaleString('en-us')}`}
            </Item>
            <Item
              style={tableStyle}
              color={
                result?.profitRatio6M && result?.profitRatio6M > 0
                  ? TICKCOLOR_UP
                  : result?.profitRatio6M && result?.profitRatio6M < 0
                  ? TICKCOLOR_DOWN
                  : TICKCOLOR_NEUTRAL
              }
            >
              {result?.profitRatio6M > 0 ? (
                <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
              ) : result?.profitRatio6M < 0 ? (
                <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
              ) : (
                ''
              )}
              {`${Math.abs(result?.profitRatio6M).toFixed(2).toLocaleString('en-us')}%`}
            </Item>
          </Box>
        )
      )}
    </Stack>
  );
};
export default SearchSimulation;
