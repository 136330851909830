import { isDomestic } from '../../lib/utils';
import { Box, Typography } from '@mui/material';
import {
  ChartsAxisHighlight,
  ChartsTooltip,
  ChartsXAxis,
  ChartsYAxis,
  LineHighlightPlot,
  LinePlot,
  ResponsiveChartContainer
} from '@mui/x-charts';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const PerformanceChart = ({ plotData, country }) => {
  const series = [
    {
      type: 'bar',
      yAxisId: 'balance',
      label: 'Account Balance',
      color: 'lightgray',
      data: plotData.map(day => day.accountBalance),
      highlightScope: { highlight: 'item' }
    },
    {
      type: 'line',
      yAxisId: 'profit',
      label: 'Profit',
      color: '#00AEEF',
      data: plotData.map(day => day.profit),
      highlightScope: { highlight: 'item' }
    },
    {
      type: 'line',
      yAxisId: 'profit',
      color: 'red',
      label: 'Profit Ratio',
      data: plotData.map(day => day.profitRatio),
      valueFormatter: (v, { dataIndex }) => {
        if (!v) return;

        const { profitRatio } = plotData[dataIndex];
        return profitRatio.toFixed(2) + '%';
      }
    }
  ];

  return (
    plotData.length > 0 && (
      <Box display={'flex'} gap={2} pt={4} flexDirection={'column'}>
        <Typography variant='h6'>Performance Chart</Typography>

        <ResponsiveChartContainer
          series={series}
          height={450}
          margin={{ top: 16 }}
          xAxis={[
            {
              id: 'date',
              data: plotData.map(day => new Date(day.date)),
              scaleType: 'band',
              valueFormatter: value => value.toLocaleDateString()
            }
          ]}
          yAxis={[
            {
              id: 'profit',
              scaleType: 'linear',
              // valueFormatter: value => `${(value / 1000_000).toLocaleString()}M`
              valueFormatter: value => {
                if (isDomestic(country)) {
                  return `${(value / 1_000_000).toLocaleString()}M`;
                } else {
                  return `$${(value / 1000).toLocaleString()}K`;
                }
              }
            },
            {
              id: 'balance',
              scaleType: 'linear',
              // valueFormatter: value => `${(value / 1000_000).toLocaleString()}M`
              valueFormatter: value => {
                if (isDomestic(country)) {
                  return `${(value / 1_000_000).toLocaleString()}M`;
                } else {
                  return `$${(value / 1000).toLocaleString()}K`;
                }
              }
            }
          ]}
        >
          <ChartsAxisHighlight x='line' />
          <LinePlot />
          <LineHighlightPlot />
          <ChartsXAxis
            label='Date'
            position='bottom'
            axisId='date'
            tickInterval={(value, index) => {
              return index % 3 === 0;
            }}
            tickLabelStyle={{ fontSize: 12 }}
          />
          <ChartsYAxis
            label={isDomestic(country) ? 'Profit (KRW)' : 'Profit (USD)'}
            position='left'
            axisId='profit'
            tickLabelStyle={{ fontSize: 10 }}
            sx={{
              [`& .${axisClasses.label}`]: {
                transform: 'translateX(-12px)'
              }
            }}
          />
          <ChartsYAxis
            label={isDomestic(country) ? 'Account Balance (KRW)' : 'Account Balance (USD)'}
            position='right'
            axisId='balance'
            tickLabelStyle={{ fontSize: 10 }}
            sx={{
              [`& .${axisClasses.label}`]: {
                transform: 'translateX(12px)'
              }
            }}
          />
          <ChartsTooltip
            slotProps={{
              popper: {
                sx: {
                  '& .MuiChartsTooltip-paper': {
                    backgroundColor: 'black'
                  }
                }
              }
            }}
          />
        </ResponsiveChartContainer>
      </Box>
    )
  );
};

export default PerformanceChart;
