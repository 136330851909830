import { Box, styled, Typography } from '@mui/material';
import { isDomestic } from '../../lib/utils';
import { TICKCOLOR_UP, TICKCOLOR_DOWN, TICKCOLOR_NEUTRAL } from '../../lib/constants';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SimulationSummary = ({ result, country }) => {
  const Item = styled(Typography)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.body2
    // padding: theme.spacing(1),
  }));

  const tableStyle = {
    fontSize: '14px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    lineHeight: '52px',
    paddingLeft: '1rem'
  };

  return (
    <>
      <Box
        display={'flex'}
        gap={2}
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant='h6'>
          Simulation Result - <span style={{ color: '#00AEEF' }}>{result.fund}</span> fund
        </Typography>
        <Typography variant='body1' color={'primary'}>
          Algorithm: {result.ai}
        </Typography>
      </Box>

      <Box
        display={'grid'}
        gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
        border={'1px solid rgba(255, 255, 255, 0.2)'}
        borderRadius={'6px'}
      >
        <Item style={tableStyle} backgroundColor='#001021'>
          Period
        </Item>
        <Item style={tableStyle}>
          {result.startDate} ~ {result.endDate}
        </Item>
        <Item style={tableStyle} backgroundColor='#001021'>
          Account Balance
        </Item>
        <Item style={tableStyle}>
          {isDomestic(country)
            ? Math.abs(result.accountBalance).toLocaleString('en-us')
            : `$${Math.abs(result.accountBalance).toLocaleString('en-us', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`}
        </Item>
        <Item style={tableStyle} backgroundColor='#001021'>
          Asset Balance
        </Item>
        <Item style={tableStyle}>
          {isDomestic(country)
            ? Math.abs(result.assetBalance).toLocaleString('en-us')
            : `$${Math.abs(result.assetBalance).toLocaleString('en-us', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`}
        </Item>
        <Item style={tableStyle} backgroundColor='#001021'>
          Cash Balance
        </Item>
        <Item style={tableStyle}>
          {isDomestic(country)
            ? Math.abs(result.cashBalance).toLocaleString('en-us')
            : `$${Math.abs(result.cashBalance).toLocaleString('en-us', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}`}
        </Item>
        <Item style={tableStyle} backgroundColor='#001021'>
          Profit
        </Item>
        <Item
          style={tableStyle}
          color={
            result.profit && result.profit > 0
              ? TICKCOLOR_UP
              : result.profit && result.profit < 0
              ? TICKCOLOR_DOWN
              : TICKCOLOR_NEUTRAL
          }
        >
          {result.profit > 0 ? (
            <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
          ) : result.profit < 0 ? (
            <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
          ) : (
            ''
          )}
          {isDomestic(country)
            ? Math.abs(result.profit).toLocaleString('en-us')
            : `$${Math.abs(result.profit).toFixed(2).toLocaleString('en-us')}`}
        </Item>
        <Item style={tableStyle} backgroundColor='#001021'>
          Profit %
        </Item>
        <Item
          style={tableStyle}
          color={
            result.profitRatio && result.profitRatio > 0
              ? TICKCOLOR_UP
              : result.profitRatio && result.profitRatio < 0
              ? TICKCOLOR_DOWN
              : TICKCOLOR_NEUTRAL
          }
        >
          {result.profit > 0 ? (
            <ArrowDropUpIcon style={{ marginLeft: '-8px' }} />
          ) : result.profit < 0 ? (
            <ArrowDropDownIcon style={{ marginLeft: '-8px' }} />
          ) : (
            ''
          )}
          {Math.abs(result.profitRatio).toFixed(2).toLocaleString('en-us') + '%'}
        </Item>
      </Box>
    </>
  );
};
export default SimulationSummary;
