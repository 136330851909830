import axios from 'axios';

export const simulateAsset = async (token, tradingMode, asset, startDate, endDate) => {
  try {
    const API_URL = `/api/${tradingMode.toLowerCase()}/trades/simulate/asset`;
    const country = asset.country;

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`
      }
    };

    const data = {
      asset,
      startDate,
      endDate,
      country
    };

    const response = await axios.post(API_URL, data, config);

    return response.data;
  } catch (error) {
    // console.log(error);

    throw error;
  }
};
